import React from "react";
import PropTypes from "prop-types";

const IconForward = ({ attributes }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...attributes}
  >
    <path
      d="M12.8,4.9 C12.8,5.02381014 12.7547624,5.13095193 12.6642857,5.22142857 L9.00714286,8.87857143 C8.91666621,8.96904807 8.80952443,9.01428571 8.68571429,9.01428571 C8.56190414,9.01428571 8.45476236,8.96904807 8.36428571,8.87857143 C8.27380907,8.78809479 8.22857143,8.680953 8.22857143,8.55714286 L8.22857143,6.72857143 L6.62857143,6.72857143 C6.16190243,6.72857143 5.74404946,6.742857 5.375,6.77142857 C5.00595054,6.80000014 4.63928754,6.85119011 4.275,6.925 C3.91071246,6.99880989 3.59404896,7.09999936 3.325,7.22857143 C3.05595104,7.3571435 2.80476307,7.52261804 2.57142857,7.725 C2.33809407,7.92738196 2.14761979,8.16785575 2,8.44642857 C1.85238021,8.72500139 1.73690518,9.05476 1.65357143,9.43571429 C1.57023768,9.81666857 1.52857143,10.2476166 1.52857143,10.7285714 C1.52857143,10.9904775 1.54047607,11.2833317 1.56428571,11.6071429 C1.56428571,11.6357144 1.57023804,11.6916663 1.58214286,11.775 C1.59404768,11.8583337 1.6,11.9214284 1.6,11.9642857 C1.6,12.0357146 1.57976211,12.0952379 1.53928571,12.1428571 C1.49880932,12.1904764 1.4428575,12.2142857 1.37142857,12.2142857 C1.29523771,12.2142857 1.22857171,12.1738099 1.17142857,12.0928571 C1.13809507,12.0499998 1.107143,11.9976194 1.07857143,11.9357143 C1.04999986,11.8738092 1.01785732,11.8023814 0.982142857,11.7214286 C0.946428393,11.6404758 0.921428643,11.5833335 0.907142857,11.55 C0.302377929,10.1928504 0,9.11905157 0,8.32857143 C0,7.38094764 0.126189214,6.58809843 0.378571429,5.95 C1.15000386,4.03094279 3.23331636,3.07142857 6.62857143,3.07142857 L8.22857143,3.07142857 L8.22857143,1.24285714 C8.22857143,1.119047 8.27380907,1.01190521 8.36428571,0.921428571 C8.45476236,0.830951929 8.56190414,0.785714286 8.68571429,0.785714286 C8.80952443,0.785714286 8.91666621,0.830951929 9.00714286,0.921428571 L12.6642857,4.57857143 C12.7547624,4.66904807 12.8,4.77618986 12.8,4.9 L12.8,4.9 Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

IconForward.propTypes = {
  attributes: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export default IconForward;
