/* eslint-disable */
/*! modernizr 3.3.1 (Custom Build) | MIT *
 * http://modernizr.com/download/?-mq-setclasses !*/
!(function (e, n, t) {
  function o(e, n) {
    return typeof e === n;
  }
  function a() {
    var e, n, t, a, s, i, r;
    for (var l in d)
      if (d.hasOwnProperty(l)) {
        if (
          ((e = []),
          (n = d[l]),
          n.name &&
            (e.push(n.name.toLowerCase()),
            n.options && n.options.aliases && n.options.aliases.length))
        )
          for (t = 0; t < n.options.aliases.length; t++)
            e.push(n.options.aliases[t].toLowerCase());
        for (a = o(n.fn, "function") ? n.fn() : n.fn, s = 0; s < e.length; s++)
          (i = e[s]),
            (r = i.split(".")),
            1 === r.length
              ? (Modernizr[r[0]] = a)
              : (!Modernizr[r[0]] ||
                  Modernizr[r[0]] instanceof Boolean ||
                  (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])),
                (Modernizr[r[0]][r[1]] = a)),
            f.push((a ? "" : "no-") + r.join("-"));
      }
  }
  function s(e) {
    var n = u.className,
      t = Modernizr._config.classPrefix || "";
    if ((p && (n = n.baseVal), Modernizr._config.enableJSClass)) {
      var o = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(o, "$1" + t + "js$2");
    }
    Modernizr._config.enableClasses &&
      ((n += " " + t + e.join(" " + t)),
      p ? (u.className.baseVal = n) : (u.className = n));
  }
  function i() {
    return "function" != typeof n.createElement
      ? n.createElement(arguments[0])
      : p
      ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0])
      : n.createElement.apply(n, arguments);
  }
  function r() {
    var e = n.body;
    return e || ((e = i(p ? "svg" : "body")), (e.fake = !0)), e;
  }
  function l(e, t, o, a) {
    var s,
      l,
      f,
      d,
      c = "modernizr",
      p = i("div"),
      m = r();
    if (parseInt(o, 10))
      for (; o--; )
        (f = i("div")), (f.id = a ? a[o] : c + (o + 1)), p.appendChild(f);
    return (
      (s = i("style")),
      (s.type = "text/css"),
      (s.id = "s" + c),
      (m.fake ? m : p).appendChild(s),
      m.appendChild(p),
      s.styleSheet
        ? (s.styleSheet.cssText = e)
        : s.appendChild(n.createTextNode(e)),
      (p.id = c),
      m.fake &&
        ((m.style.background = ""),
        (m.style.overflow = "hidden"),
        (d = u.style.overflow),
        (u.style.overflow = "hidden"),
        u.appendChild(m)),
      (l = t(p, e)),
      m.fake
        ? (m.parentNode.removeChild(m), (u.style.overflow = d), u.offsetHeight)
        : p.parentNode.removeChild(p),
      !!l
    );
  }
  var f = [],
    d = [],
    c = {
      _version: "3.3.1",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function (e, n, t) {
        d.push({ name: e, fn: n, options: t });
      },
      addAsyncTest: function (e) {
        d.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = c), (Modernizr = new Modernizr());
  var u = n.documentElement,
    p = "svg" === u.nodeName.toLowerCase(),
    m = (function () {
      var n = e.matchMedia || e.msMatchMedia;
      return n
        ? function (e) {
            var t = n(e);
            return (t && t.matches) || !1;
          }
        : function (n) {
            var t = !1;
            return (
              l(
                "@media " + n + " { #modernizr { position: absolute; } }",
                function (n) {
                  t =
                    "absolute" ==
                    (e.getComputedStyle
                      ? e.getComputedStyle(n, null)
                      : n.currentStyle
                    ).position;
                }
              ),
              t
            );
          };
    })();
  (c.mq = m), a(), s(f), delete c.addTest, delete c.addAsyncTest;
  for (var h = 0; h < Modernizr._q.length; h++) Modernizr._q[h]();
  e.Modernizr = Modernizr;
})(window, document);
